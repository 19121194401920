<template>
    <div>
        <jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
            <div>
                <el-form :model="diaform" ref="diaform" :disabled="isCheck" :rules="rules">
                    <el-form-item label="班次调整类别:" prop="bianhao" :label-width="formLabelWidth">
                        <el-input v-model="diaform.bianhao"></el-input>
                    </el-form-item>
                    <el-form-item label="班次调整原因:" prop="name" :label-width="formLabelWidth">
                        <el-input v-model="diaform.name"></el-input>
                    </el-form-item>
                    <el-form-item label="备注:" prop="remark" :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="!isCheck" slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="save">确 定</el-button>
            </div>
        </jy-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',
            isCheck: false,

            diaform: {
                bianhao: '',
                name: '',
                remark: ''
            },
            dialogVisible: false,
            formLabelWidth: '120px',

            rules: {
                bianhao: [
                    {
                        required: true,
                        message: '请输入班次调整类别',
                        trigger: 'blur'
                    }
                ],
                name: [
                    {
                        required: true,
                        message: '请输入班次调整原因',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm('diaform')
            }
        }
    },
    methods: {
        // 打开对话框
        init(option, type) {
            this.dialogVisible = true
            this.type = type || 'add'
            if (this.type == 'check') {
                this.title = '查看'
                this.isCheck = true
                this.$nextTick(() => {
                    this.view(option)
                })
            } else {
                this.isCheck = false
                if (this.type == 'update') {
                    this.title = '编辑'
                    this.$nextTick(() => {
                        this.view(option)
                    })
                } else {
                    this.title = '新增'
                }
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },

        cancel() {
            this.dialogVisible = false
            console.log('cancel')
        },
        view(option) {
            let param = {}
            param['supplierId'] = option.supplierId
            this.$http
                .post('/info/dictsupplierinfo/detail', param)
                .then(res => {
                    this.diaform = res.detail
                })
        },
        save() {
            this.$refs.diaform.validate(valid => {
                if (valid) {
                    let url = ''
                    if (this.type == 'add') {
                        url = '/info/dictsupplierinfo/save'
                    } else {
                        url = '/info/dictsupplierinfo/update'
                    }

                    let option = this.diaform
                    this.$http.post(url, option).then(res => {
                        if (res.code == 0) {
                            this.$message.success(res.msg)
                            this.dialogVisible = false
                            this.$emit('updateList')
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>